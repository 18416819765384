import axios from "axios";

const context = "/servicesupport";

export function getTextMessagePagination(data: any) {
  return axios.get(`${context}/alimenssage/_code/pagination`, { params: data });
}

export function getTextMessageList(data: any) {
  return axios.get(`${context}/alimenssage/_code/list`, { params: data });
}

export function getVoiceMessagePagination(data: any) {
  return axios.get(`${context}/alimenssage/_voice/pagination`, {
    params: data
  });
}

export function getVoiceMessageList(data: any) {
  return axios.get(`${context}/alimenssage/_voice/list`, { params: data });
}
